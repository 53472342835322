exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-heart-failure-management-guidelines-js": () => import("./../../../src/pages/heart-failure-management-guidelines.js" /* webpackChunkName: "component---src-pages-heart-failure-management-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-savings-and-resources-js": () => import("./../../../src/pages/savings-and-resources.js" /* webpackChunkName: "component---src-pages-savings-and-resources-js" */),
  "component---src-pages-scored-trial-js": () => import("./../../../src/pages/scored-trial.js" /* webpackChunkName: "component---src-pages-scored-trial-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-soloist-whf-trial-js": () => import("./../../../src/pages/soloist-whf-trial.js" /* webpackChunkName: "component---src-pages-soloist-whf-trial-js" */)
}

